import { Store } from 'reflux';
import AdminActions from '../actions/AdminActions'

class AdminStore extends Store {
    constructor() {
        super()
        this.listenables = AdminActions
        this.state = {
            refresh: false,
            users: null,
            referrers: null,
            usersCount: null,
            action: '',
            credite: null,
            achizitii: null,
            rapoarte: null,
            emailsThreshold: null,
            statusFin: null,
            statusCip: null,
            apiStatus: null,
            apiLoading: false,
            apiResponse: null,
            firmeMonitorizate: null,
            monPattern: null,
            emails: [],
            emailsCount: null,
            rapAutomatizate: [],
            rapCount: null,
            rapAutoUseri: [],
            rapUserCount: null,
            rapTypes: [],
            userContract: null,
            dashboard: null,
            monInfo: null,
            monStatus: 0,
            insvGroups: [],
            insvDates: [],
            insvGroupInfo: null,
            dosare: [],
            observatii: [],
            loading: false,
            loadingMon: false,
            procRez: [],
        }
    }

    onSetAction() {
        this.setState({ action: '' })
    }

    onGetUsersCompleted(data) {
        this.setState({ users: data.Result, referrers: data.Referrers })
    }

    onSearchUserCompleted(data) {
        this.setState({ users: data.Result, usersCount: data.Count })
    }

    onGetReferrersCompleted(data) {
        this.setState({ referrers: data })
    }

    onClearUsers() {
        this.setState({ users: null })
    }

    onFilterUsers() {
        this.setState({ loading: true })
    }

    onFilterUsersCompleted(data) {
        this.setState({ users: data.Result, usersCount: data.Count, loading: false })
    }

    onUpdateUserCompleted(data) {
        let updateIx = this.state.users.findIndex(user => user.userId === data.userId)
        let usersUpdated = this.state.users
        usersUpdated[updateIx] = data
        this.setState({ users: usersUpdated })
    }

    onGetCrediteUserCompleted(data) {
        this.setState({ credite: data })
    }

    onAddCrediteUserCompleted(data) {
        let creditIx = this.state.credite.findIndex(credit => credit.TipRaport === data.TipRaport)
        let creditNew = this.state.credite.find(credit => credit.TipRaport === data.TipRaport)
        creditNew.Cumparate = creditNew.Cumparate + data.NrCredite
        creditNew.Ramase = creditNew.Ramase + data.NrCredite
        let crediteNew = this.state.credite
        crediteNew[creditIx] = creditNew
        this.setState({ credite: crediteNew })
    }

    onGetAchizitiiUserCompleted(data) {
        this.setState({ achizitii: data })
    }

    onGetConsumUserCompleted(data) {
        this.setState({ rapoarte: data })
    }

    onGetEmailsThresholdCompleted(data) {
        this.setState({ emailsThreshold: data })
    }

    onGetStatusFinCompleted(data) {
        this.setState({ statusFin: data })
    }

    onGetCipStatusCompleted(data) {
        this.setState({ statusCip: data })
    }

    onGetApiStatusCompleted(data) {
        this.setState({ apiStatus: data })
    }

    onVerifyApi() {
        this.setState({ apiLoading: true })
    }

    onVerifyApiCompleted(data) {
        this.setState({ apiResponse: data, apiLoading: false })
    }

    onResetApi() {
        this.setState({ apiResponse: null })
    }

    onClearFirmeMon() {
        this.setState({ firmeMonitorizate: [], monPattern: null })
    }

    onGetFirmeMonitorizate() {
        this.setState({ loadingMon: true })
    }

    onGetFirmeMonitorizateCompleted(data) {
        this.setState({ firmeMonitorizate: data.firme, monPattern: data.pattern, loadingMon: false })
    }

    onGetEmails() {
        this.setState({ loading: true })
    }

    onGetEmailsCompleted(data) {
        this.setState({ emails: data.Result, emailsCount: data.Count, loading: false })
    }

    onClearEmails() {
        this.setState({ emails: [], emailsCount: 0 })
    }

    onGetRapAutomatizateCompleted(data) {
        this.setState({ rapAutomatizate: data.Result, rapCount: data.Count })
    }

    onGetRapAutomatizateUserCompleted(data) {
        this.setState({ rapAutoUseri: data.Result, rapUserCount: data.Count })
    }

    onClearRapAutoUseri() {
        this.setState({ rapAutoUseri: [], rapUserCount: 0 })
    }

    onGetRapoarteTypesCompleted(data) {
        this.setState({ rapTypes: data })
    }

    onGetUserContractCompleted(data) {
        this.setState({ userContract: data })
    }

    onGetDashboardInfoCompleted(data) {
        this.setState({ dashboard: data })
    }

    onGetMonPeriodiceUseriCompleted(data) {
        this.setState({ monInfo: data })
    }

    onClearMonUseri() {
        this.setState({ monInfo: null })
    }

    onGetMonProcesareIdCompleted(data) {
        this.setState({ monStatus: data })
    }

    onGetInsvGroupsCompleted(data) {
        this.setState({ insvGroups: data.Useri, insvDates: data.LastDates })
    }

    onFilterInsvGroupsCompleted(data) {
        this.setState({ insvGroups: data })
    }

    onClearInsvGroups() {
        this.setState({ insvGroups: [] })
    }

    onGetInsvGroupInfoCompleted(data) {
        this.setState({ insvGroupInfo: data })
    }

    onGetUserDosareCompleted(data) {
        this.setState({ dosare: data })
    }

    onGetUserObservatiiCompleted(data) {
        this.setState({ observatii: data })
    }

    onExtragerePuncteLucruCompleted(data) {
        this.setState({ procRez: data })
    }
}

export default AdminStore